<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
             <CRow>
                <CCol col="6" class="text-left">                   
                    <h4>Edit User Group</h4>   
                </CCol> 
                <CCol col="6" class="text-right">
                      <b-button-group>                
                         <b-button variant="warning" v-on:click="actionEdit()" v-if="seenBtnWarn">Edit</b-button>
                         <b-button v-on:click="actionCancel()" v-if="seenBtnCcel">Cancel</b-button>
                         <b-button variant="success" v-on:click="actionUpdate()" v-if="seenBtnUpdate">Update</b-button>
                      </b-button-group> 
                </CCol> 
             </CRow>
          </CCardHeader>
          <CCardBody>
             <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
             </CRow> 

             <b-form>  

               <CInput
                type="text" 
                label="Unique Number"
                placeholder="Type Unique Number"
                :disabled="!isDisabledUN"
                v-model="groupUniqueNumber"               
                horizontal                
               />

               <CInput
                type="text" 
                label="Group Name"
                placeholder="Type Group Name"
                :disabled="!isDisabledGN"
                v-model="name"               
                horizontal                
               />

               <b-row>                  
                  <b-col sm="3">                    
                     <label for="input-small" >Features:</label>
                  </b-col>   
                  <b-col sm="9"> 
                    <multiselect @select="toggleFeatureSelected" @remove="toggleFeatureUnSelect" :disabled="!isDisabledFeat" v-model="features" :options="optfeatures" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                    <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>
                  </b-col>
                </b-row><br/>

                <b-row v-if="seenBtnAddLim">
                    <b-col sm="3">                    
                       <label for="input-small" >Country Code</label>
                    </b-col>   
                    <b-col sm="4"> 
                           <vue-typeahead-bootstrap v-model="countryCode"  :disabled="!isDisabledCC" :data="optcCode">
                           </vue-typeahead-bootstrap>
                    </b-col>

                    <b-col sm="5">
                         <b-button-group>
                           <b-button  v-on:click="AddLimit()" variant="primary">Add Limit</b-button>
                           <b-button  v-on:click="RemoveLimit()" variant="danger">Remove</b-button>
                         </b-button-group> 
                    </b-col>
                </b-row><br/>

                <b-tabs content-class="mt-3">
                    <div v-for="(value, key, index) in tabs">                
                      <b-tab :title="value" >

                      <div class="accordion" role="tablist">
                      <div v-for="(value2, key2, index2) in acdlist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle="'accordion' + key2" variant="info">{{ value2.title }}</b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion' + key2" visible accordion="my-accordion" role="tabpanel">
                              <b-card-body>

                                <span v-if="value2.key == 'firstTransferLimit'">

                                    <CInput
                                        type="number"
                                        min=0
                                        :disabled="!isDisabledftl" 
                                        :name="value +'|'+value2.key"
                                        v-model="form[value +'|'+value2.key]"
                                        :label="value2.title"               
                                        :placeholder="'Type ' + value2.title"               
                                        horizontal                
                                      />
                                </span>
                                <span v-else>
                                  <div v-for="(value3, key3, index3) in fieldlist">
                                      <CInput
                                        type="number"
                                         min=0  
                                        :disabled="!isDisabledfield"                                      
                                        :name="value +'|'+value2.key +'|'+ value3.key"
                                        v-model="form[value +'|'+value2.key +'|'+value2.abbr+value3.key]"
                                        :label="value3.label"               
                                        :placeholder="'Type ' + value3.label"               
                                        horizontal                
                                      />
                                  </div>
                                </span>


                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>    
                      </div>


                      </b-tab>
                    </div>
                 </b-tabs>

            </b-form>

          </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js';
 import Multiselect from 'vue-multiselect';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import CurrDialCode from '@/assets/currwithdialcode.json'; 
 import DialCodeCurr from '@/assets/dialcodewithcurr.json'; 
 import Currency from '@/assets/currency.json'; 
 import CPhone from '@/assets/phone.json'; 

export default { 
    name: 'editusergroup',
    components: {  
      VueTypeaheadBootstrap,  
      Multiselect        
    },        
    data() {
      return { 
          seenBtnCcel: false,
          seenBtnWarn: true,
          seenBtnUpdate:false,
          seenBtnAddLim:false,
         
          isDisabledftl:false,
          isDisabledfield:false,
          isDisabledUN:false,
          isDisabledGN:false,
          isDisabledFeat:false,
          isDisabledCC:false,
          initfeatures:[],
          groupUniqueNumber:'',
          name:'',
          features:[],
          optfeatures:[],
          optcCode:[],
          shadwdata: [],         
          countryCode:'',
          tabs:[],
          form: [],
          shadwdata: [],
          acdlist:[ 
            { abbr : 'dcl', key: 'defaultCurrencyLimits', title: 'Default Currency Limit'},
            { abbr : 'tucl', key: 'topupCurrencyLimits', title: 'Topup Currency Limit'},
            { abbr : 'p2p', key: 'p2pCurrencyLimits', title: 'P2P Currency Limit'},
            { abbr : 'p2m', key: 'p2mCurrencyLimits', title: 'P2M Currency Limit'},
            { abbr : 'rcl', key: 'remittanceCurrencyLimits', title: 'Remittance Currency Limits'},
            { abbr : 'ftl', key: 'firstTransferLimit', title: 'First Transfer Limit'},
          ],
          fieldlist:[
              { abbr : 'dsl', key: 'dailySendLimit', label: 'Daily Send Limit'},
              { abbr : 'drl', key: 'dailyReceiveLimit', label: 'Daily Receive Limit'},
              { abbr : 'msl', key: 'monthlySendLimit', label: 'Monthly Send Limit'},
              { abbr : 'mrl', key: 'monthlyReceiveLimit', label: 'Monthly Receive Limit'},
              { abbr : 'asl', key: 'annualSendLimit', label: 'Annual Send Limit'},
              { abbr : 'arl', key: 'annualReceiveLimit', label: 'Annual Receive Limit'},
              { abbr : 'trsl', key: 'transactionSendLimit', label: 'Transaction Send Limit'},
              { abbr : 'trrl', key: 'transactionReceiveLimit', label: 'Transaction Receive Limit'},
          ],
          count:1,     
          seen:false,
          msg :'',
          color:''
        };
    },
    created () { 
        this.getSingleUserGroup(this.$route.params.id);        
        this.getConfigList();                      
    },
    methods: { 

      actionEdit(){

         this.seenBtnCcel=true;
         this.seenBtnWarn=false;
         this.seenBtnUpdate=true;
         this.seenBtnAddLim=true;
         
         this.isDisabledftl=true;
         this.isDisabledfield=true;
         this.isDisabledGN=true;
         this.isDisabledFeat=true;
         this.isDisabledCC=true;

      },  

      actionCancel(){

         this.seenBtnCcel=false;
         this.seenBtnWarn=true;
         this.seenBtnUpdate=false;
         this.seenBtnAddLim=false;
        
         this.isDisabledftl=false;
         this.isDisabledfield=false;
         this.isDisabledGN=false;
         this.isDisabledFeat=false;
         this.isDisabledCC=false;
      },

      actionUpdate(){

            var formdata = this.form; let params = {};  let headers = {};      
            var i=0;  
            for (var key in formdata) {  //console.log(key); 
                var value  = parseInt(formdata[key]);  
                var fields = key.split("|"); 
                i=0;    
                for (var key2 in fields) {  //console.log(i); * i value should be reset each loop
                  if(i==0){
                      var curkey = fields[key2];  
                      var curr = DialCodeCurr[curkey]; 
                      var temp = this.tabs;
                      var idx  = temp.findIndex(temp => temp === curkey);                
                  } else if(i==1){
                     var idx1 = fields[key2]; 
                     if(idx1=="firstTransferLimit"){
                        this.shadwdata[idx][curr][idx1][curr]['maxTransferAmount'] = value;
                     } 
                  } else if(i==2){ 
                     var idx2 = fields[key2];   
                     this.shadwdata[idx][curr]['transactionLimits'][idx1][curr][idx2] = value;          
                  } 
                  i++;
                }
            }

            for (var key3 in this.shadwdata) { 
                var subs = this.shadwdata[key3];
                for (var key4 in subs) {   
                    var fkey = subs[key4].countryCode;
                    params[fkey] = subs[key4];
                } 
            }  

            var str     = JSON.stringify(params);        
            var strdcl  = str.replace(/dcl/g,"");
            var strtucl = strdcl.replace(/tucl/g,"");
            var strp2p  = strtucl.replace(/p2p/g,"");
            var strp2m  = strp2p.replace(/p2m/g,"");
            var strrcl  = strp2m.replace(/rcl/g,"");
            let result  = JSON.parse(strrcl);       

            var arrFeatures = []; 
            if(this.userGroup !== 'undefined'){
              for (var key in this.features) {
                  var feature  = this.features[key].name;                          
                  arrFeatures.push(feature);
              }
            }
            headers['features'] = arrFeatures;
            headers['groupUniqueNumber'] = this.groupUniqueNumber;
            headers['limits'] = result;
            headers['name'] = this.name;  //console.log(headers);

            var validate = [];
            for (var key in headers) {
              if(!headers[key]) { validate.push(false); } else { validate.push(true); }
            }
            
            if(!validate.includes(false)){  
                this.updateUserGroup(headers);
            } else {           
               this.msg   = 'Data Not Complete, All Data Required!'; 
               this.color = 'warning'; this.seen  = true;
               setTimeout( () =>  this.seen=false , 5000);
            }

      }, 

      updateUserGroup: function(params) { 
          TransactService.updateUserGroup(params).then(resp => {   //console.log(resp); 

               if(resp.message==="Success"){
                  this.tabs = [];   this.shadwdata = [];
                  this.getSingleUserGroup(this.$route.params.id); 
                  this.actionCancel();
                  this.msg   = "Update User Group Success!"; 
                  this.color = 'success'; this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000); 
               } else {
                  this.msg   = "Update User Group Failed!"; 
                  this.color = 'danger'; this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000); 
               }
            
           }, error => {
            console.log('error');    
          });
      },     

      AddLimit(){
            var key  = this.countryCode; 
            var code = CPhone[key]; 
            var selectcurr = Currency[key];
            
            if(!this.tabs.includes(code) && code) {
                this.tabs.push(code); 

                let temp   = {};  let temp2  =  {}; let temp3  =  {}; let temp4  =  {}; 
                let fields =  {}; let field =  {};  let result =  {}; let test =  {};

                var items = this.acdlist; var i = this.count;
                for (var key in items) {  
                    var groupName = items[key].key;  
                    var groupAbbr = items[key].abbr; 

                  if(groupName !== "firstTransferLimit"){
                    for (var key2 in this.fieldlist) { 
                        var fieldName = this.fieldlist[key2].key;                     
                        field[groupAbbr+fieldName] = 0;  
                    i++} 
                    
                    temp2[selectcurr] = field;    field = {};      
                    fields[groupName] = temp2;    temp2 = {};
                  }              
                }

                this.count=i; 

                temp['countryCode']        = code;
                temp['transactionLimits']  = fields;
                temp3['maxTransferAmount'] = 0;
                temp4[selectcurr] = temp3; 
                temp['firstTransferLimit'] = temp4;
                result[selectcurr] = temp;
                this.shadwdata.push(result);   console.log(this.shadwdata);
            }

      },

      RemoveLimit(){
          var key   = this.countryCode;
          var ccode = (key!=="*") ? CPhone[key] : "*";
          var curr  = (key!=="*") ? DialCodeCurr[ccode] : "*"; 
          let temp  = this.tabs;
          let index = temp.findIndex( temp => temp === ccode);
          if (index > -1) {  this.tabs.splice(index, 1); }

          //================
          let temp2   = this.shadwdata;
          for (var key in temp2) { 
              var temp3 = temp2[key];

                for (var key2 in temp3) {
                    if(key2===curr){
                        this.shadwdata.splice(key, 1);
                    }
                }
          } 

          console.log(this.shadwdata);

      },

      setLimit(key){  
        
            var code   = key;
            var selectcurr = (key!=="*") ? DialCodeCurr[key] : key;  
            let temp   = {};  let temp2  =  {}; let temp3  =  {}; let temp4  =  {}; 
            let fields =  {}; let field =  {};  let result =  {}; let test =  {};

            var items = this.acdlist; var i = this.count;

                for (var key in items) {
                  var groupName = items[key].key;  
                  var groupAbbr = items[key].abbr; 

                  if(groupName !== "firstTransferLimit"){
                    for (var key2 in this.fieldlist) { 
                        var fieldName      = this.fieldlist[key2].key;                    
                        field[groupAbbr+fieldName] = 0; 
                    i++} 
                    temp2[selectcurr] = field;    field = {};      
                    fields[groupName] = temp2;    temp2 = {};
                  }              
                }

            this.count=i; 

            temp['countryCode']        = code;
            temp['transactionLimits']  = fields;
            temp3['maxTransferAmount'] = 0;
            temp4[selectcurr] = temp3; 
            temp['firstTransferLimit'] = temp4;
            result[selectcurr] = temp;
            this.shadwdata.push(result);   //console.log(result);

      },

      toggleFeatureSelected(){
      },

      toggleFeatureUnSelect(){
      },

      getSingleUserGroup: function(id) { 
          TransactService.getSingleUserGroup(id).then(resp => {  //console.log(resp);

              this.groupUniqueNumber = resp.groupUniqueNumber;
              this.name = resp.name;
             // this.initfeatures = resp.features;

              var limits = resp.limits;
              for (var key in limits) { 
                     this.tabs.push(key); this.setLimit(key);

                     var lim  = limits[key];  //console.log(lim);               
                     var curr = DialCodeCurr[lim.countryCode]; 

                     for (var key3 in this.acdlist) {
                        var groupname = this.acdlist[key3].key;
                        var abbr = this.acdlist[key3].abbr;

                        if(groupname!=="firstTransferLimit"){
                             var temp = lim.transactionLimits.defaultCurrencyLimits;
                             for (var key6 in temp) { 
                                 for (var key4 in this.fieldlist) {
                                    var field = this.fieldlist[key4].key; //console.log(key6+'|'+field);
                                    var value = lim.transactionLimits.defaultCurrencyLimits[key6][field];                                    
                                    this.form[key+'|'+groupname+'|'+abbr+field] = value;                                
                                 }
                             }

                        } else {                            
                            for (var key5 in lim.firstTransferLimit) {                                   
                                var value   = lim.firstTransferLimit[key5]['maxTransferAmount'];
                                this.form[key+'|'+groupname] = value;
                            }
                        }                     
                   }

              }

          }, error => {
              console.log('error');    
          });
      },    

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {   //console.log(resp);

                var ccodeSrc =[]; var basephone = [];
                for (var key in CPhone) { ccodeSrc.push(key); }           
                this.optcCode = ccodeSrc;

                var gFeatSrc=[]; var temp = []; var baseFeatures = [];
                var groupFeatures = resp.groupFeatures;
                for (var key in groupFeatures) {
                    var gFeat = groupFeatures[key];  
                    gFeatSrc.push({ name: gFeat }); 
                }
                this.optfeatures = gFeatSrc;

                var id = this.$route.params.id;
                TransactService.getSingleUserGroup(id).then(resp2 => {

                    for (var key in resp2.features) {
                      var feature = resp2.features[key];  //console.log(feature);
                      let index   = gFeatSrc.findIndex(gFeatSrc => gFeatSrc.name === feature);           
                      this.features.push(this.optfeatures[index]);
                    }

                });

           }, error => {
              console.log('error');    
          });
      },     
      
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>